import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useMemo, useRef, MouseEventHandler, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular2/phasePoint/PhasePoint.module.scss'
import { PhasePopup } from 'pages/processBuilder/templates/circular2/phasePopup/PhasePopup'
import { FormPhase } from 'types/process/process'
import { routesManager } from 'utils/routesManager'

export const PhasePoint = ({
  phase,
  phasesCount,
  index,
  groupRotation,
  groupSize,
  isActive,
  isGroupActive,
  activePhase,
  onPhaseEnter,
  onPhaseLeave,
}: {
  phase: FormPhase
  phasesCount: number
  index: number
  groupRotation: number
  groupSize: number
  isActive: boolean
  isGroupActive: boolean
  activePhase: MayBeNull<number>
  onPhaseEnter: MouseEventHandler
  onPhaseLeave: MouseEventHandler
}) => {
  const ref = useRef(null)
  const navigate = useNavigate()
  const url = (phase.innerPageEnabled && phase?.id ? routesManager.phase.getUrl(phase?.id) : phase?.url) || ''
  const isCustomUrl = phase?.url && !phase.innerPageEnabled
  const pointRotation = useMemo(() => {
    const rotateOffset = phasesCount < 5 ? groupSize / 10 : 0.2
    const rotateStep = (groupSize - rotateOffset * 2) / (phasesCount - 1)

    return phasesCount > 1 ? rotateStep * index + rotateOffset - groupSize / 2 : 0
  }, [index, phasesCount, groupSize])

  const pointAngle = groupRotation + pointRotation
  const textRotation = useMemo(() => -(pointRotation + groupRotation), [groupRotation, pointRotation])

  const { textX, justify } = useMemo(() => {
    if (pointAngle > 0 && pointAngle < 180) {
      return {
        textX: '180',
        justify: 'start',
      }
    } else if ((pointAngle > 180 && pointAngle < 360) || pointAngle < 0) {
      return {
        textX: '-180',
        justify: 'end',
      }
    } else {
      return {
        textX: '0',
        justify: 'center',
      }
    }
  }, [pointAngle])

  const textY = useMemo(() => (pointAngle === 0 ? 0 : pointAngle === 180 ? 60 : 29), [pointAngle])

  const { popupPlacement, popupPlacementModifier } = useMemo(() => {
    const x = pointAngle >= 0 && pointAngle <= 180 ? 'left' : 'right'
    const y = pointAngle >= 90 && pointAngle <= 270 ? 'bottom' : 'top'

    const popupPlacementModifier =
      (pointAngle >= -30 && pointAngle <= 30) || (pointAngle >= 150 && pointAngle <= 210) || pointAngle > 330
        ? 'long'
        : (pointAngle >= 70 && pointAngle <= 110) || (pointAngle >= 250 && pointAngle <= 290)
        ? 'short'
        : ''

    return { popupPlacement: `${x}-${y}`, popupPlacementModifier }
  }, [pointAngle])

  const handleClick = useCallback(() => {
    isCustomUrl ? (window.location.href = url) : navigate(url)
  }, [navigate, isCustomUrl, url])

  return (
    <>
      <g transform={`rotate(${pointRotation} 640 455)`} visibility={isGroupActive ? 'visible' : 'hidden'}>
        <circle
          cx="640"
          cy="132"
          r="9"
          fill={phase?.colors?.[0] || '#001023'}
          strokeWidth={isActive ? 20 : 14}
          stroke={phase?.colors?.[0] || '#001023'}
          strokeOpacity="0.4"
          ref={ref}
          onMouseEnter={onPhaseEnter}
          onMouseLeave={onPhaseLeave}
          className={clsx({ [styles.isInteractive]: url })}
          onClick={handleClick}
        />
        <foreignObject
          x="490"
          y="87"
          width="300"
          height="100"
          transform={`rotate(${textRotation} 640 132) translate(${textX} ${textY})`}
          opacity={isActive || Number.isInteger(activePhase) ? 0 : 1}
        >
          <Flex justify={justify as any}>
            <WppTypography type="xl-heading" className={styles.title} style={{ color: phase?.colors?.[1] }}>
              {phase.title}
            </WppTypography>
          </Flex>
        </foreignObject>
      </g>
      <foreignObject>
        <PhasePopup
          target={ref.current}
          onClose={() => {}}
          phase={phase}
          placement={popupPlacement}
          placementModifier={popupPlacementModifier}
        />
      </foreignObject>
    </>
  )
}
