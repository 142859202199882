import {
  WppButton,
  WppTypography,
  WppActionButton,
  WppIconPlus,
  WppCard,
  WppIconTrash,
  WppAccordion,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useCallback } from 'react'
import { FormProvider, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreatePhaseApi } from 'api/phase/mutations/useCreatePhaseApi'
import { useEditPhaseApi } from 'api/phase/mutations/useEditPhaseApi'
import { usePhaseApi } from 'api/phase/queries/useFetchPhaseApi'
import { Flex } from 'components/common/flex/Flex'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormRichText } from 'components/form/formRichText/FormRichText'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/phaseBuilder/editPhaseModal/EditPhaseModal.module.scss'
import { getInitialValues, paletteTitles, useMapFormValuesToPayload, phaseSchema } from 'pages/phaseBuilder/utils'
import { Templates } from 'types/phase/phase'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onChange: Function
  resetEditState: () => void
  phaseId?: string
}

const templateOptions = Object.values(Templates).map(value => ({ value, label: value }))
const headerSizeOptions = [
  { value: 154, label: 'Sm' },
  { value: 184, label: 'Md' },
  { value: 214, label: 'Lg' },
  { value: 244, label: 'Xl' },
  { value: 274, label: 'XXl' },
]

export const EditPhaseModal = ({ isOpen, onClose, onCloseComplete, onChange, resetEditState, phaseId }: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data } = usePhaseApi({
    params: {
      phaseId: phaseId || '',
    },
  })
  const { mutateAsync: handleCreatePhase } = useCreatePhaseApi()
  const { mutateAsync: handleEditPhase } = useEditPhaseApi()
  const mapFormValuesToPayload = useMapFormValuesToPayload()

  const form = useForm({
    defaultValues: getInitialValues(data),
    validationSchema: phaseSchema,
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    watch,
    control,
    register,
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      const phase = await mapFormValuesToPayload(values)

      data
        ? await handleEditPhase({
            phase,
            innerPhaseId: data.id || '',
          })
        : await handleCreatePhase({
            phase,
            phaseId: phaseId || '',
          })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.PHASE] })

      enqueueToast({
        message: t('phase.edit_modal.configured_successfully'),
        type: 'success',
      })
      onClose()
    } catch (e) {
      enqueueToast({
        message: t('phase.edit_modal.not_configured'),
        type: 'error',
      })
    }
  })

  const handleCloseComplete = useCallback(() => {
    onCloseComplete()
    resetEditState()
  }, [onCloseComplete, resetEditState])

  const formData = watch()

  const {
    fields: columns,
    append: appendColumn,
    remove: removeColumn,
  } = useFieldArray({
    control,
    name: 'card.columns',
    keyName: 'formId',
  })

  useEffect(() => {
    onChange(formData)
  }, [formData, onChange])

  const handleAddColumn = useCallback(() => {
    appendColumn({
      content: '',
      icon: [],
    })
  }, [appendColumn])

  const handleRemoveColumn = useCallback(
    (index: number) => () => {
      removeColumn(index)
    },
    [removeColumn],
  )

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{ onSubmit }}
        size="m"
        disableOutsideClick
        open={isOpen}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={handleCloseComplete}
        className={styles.modal}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('phase.edit_modal.edit_phase')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={28} className={styles.modalBody}>
          <FormSelect
            labelConfig={{ text: t('phase.edit_modal.template') }}
            required
            withSearch
            placeholder={t('phase.edit_modal.choose_template')}
            name="template"
            options={templateOptions}
            defaultValue={Templates.InnerFirst}
            disabled
          />

          <WppCard>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.body')}
              </WppTypography>

              <FormFileUpload
                name="background"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_background')}
              />

              <FormPalette name="colors" titles={paletteTitles[formData?.template as Templates].page} />
            </Flex>
          </WppCard>

          <WppCard>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.header')}
              </WppTypography>

              <FormTextInput name="header.title" placeholder={t('phase.edit_modal.title')} />

              <FormTextInput name="header.description" placeholder={t('phase.edit_modal.description')} />

              <FormFileUpload
                name="header.logo"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_header_logo')}
              />

              <FormFileUpload
                name="header.background"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_header_background')}
              />

              <FormPalette name="header.colors" titles={paletteTitles[formData?.template as Templates].header} />

              <FormSelect
                required
                withSearch
                placeholder={t('phase.edit_modal.header_size')}
                name="header.height"
                options={headerSizeOptions}
                defaultValue={184}
              />

              <FormCheckbox
                name="header.isFullWidth"
                labelConfig={{
                  text: t('phase.edit_modal.header_full_width'),
                }}
              />
            </Flex>
          </WppCard>

          <WppCard className={clsx({ [styles.inactiveCard]: !formData?.card?.isEnabled })}>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.card')}
              </WppTypography>

              <FormFileUpload
                name="card.background"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_card_background')}
              />

              <FormFileUpload
                name="card.contentImage"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_custom_content')}
              />

              <FormPalette name="card.colors" titles={paletteTitles[formData?.template as Templates].card} />

              <FormCheckbox
                name="card.isEnabled"
                labelConfig={{
                  text: t('phase.edit_modal.card_enabled'),
                }}
              />
            </Flex>
          </WppCard>

          <WppCard>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.controls')}
              </WppTypography>

              <Flex direction="row" justify="between" align="center" gap={15}>
                <FormTextInput
                  name="controls.previousUrl"
                  placeholder={t('phase.edit_modal.previous_link')}
                  className={styles.inputHalfRow}
                />
                <FormTextInput
                  name="controls.nextUrl"
                  placeholder={t('phase.edit_modal.next_link')}
                  className={styles.inputHalfRow}
                />
              </Flex>

              <Flex direction="row" justify="between" align="center" gap={15}>
                <FormTextInput
                  name="controls.button.title"
                  placeholder={t('phase.edit_modal.button_title')}
                  className={styles.inputHalfRow}
                />
                <FormTextInput
                  name="controls.button.url"
                  placeholder={t('phase.edit_modal.button_link')}
                  className={styles.inputHalfRow}
                />
              </Flex>
              <FormCheckbox
                name="controls.button.isVisible"
                labelConfig={{
                  text: t('phase.edit_modal.button_visibility'),
                }}
              />

              <FormPalette name="controls.colors" titles={paletteTitles[formData?.template as Templates].controls} />
            </Flex>
          </WppCard>

          <Flex direction="column" gap={20}>
            {columns.map((item, index) => (
              <WppCard
                key={item.formId}
                className={clsx(styles.column, { [styles.inactiveCard]: formData.card?.contentImage?.[0] })}
              >
                <WppAccordion withDivider={false} className={styles.columnAccordion}>
                  <WppTypography type="s-strong" slot="header">
                    {t('phase.edit_modal.column_number', { number: index + 1 })}
                  </WppTypography>
                  <WppActionButton
                    className={styles.removeColumnBtn}
                    variant="primary"
                    onClick={handleRemoveColumn(index)}
                    slot="actions"
                  >
                    <WppIconTrash slot="icon-start" />
                  </WppActionButton>

                  <Flex direction="column" gap={10}>
                    <Controller
                      render={({ field }) => (
                        <FormFileUpload
                          {...field}
                          disabled={false}
                          size={1}
                          acceptConfig={{
                            'image/svg+xml': ['.svg'],
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'image/gif': ['.gif'],
                          }}
                          maxLabelLength={45}
                          maxFiles={1}
                          className={styles.fileInput}
                          label={t('phase.edit_modal.choose_icon')}
                        />
                      )}
                      name={`card.columns.${index}.icon`}
                      control={control}
                    />

                    <FormRichText {...register(`card.columns.${index}.content`)} />
                  </Flex>
                </WppAccordion>
              </WppCard>
            ))}

            <WppActionButton onClick={handleAddColumn} className={styles.addColumnBtn}>
              <WppIconPlus slot="icon-start" />
              {t('phase.edit_modal.add_column')}
            </WppActionButton>
          </Flex>
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose}>
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" disabled={(data && !isDirty) || !isValid} loading={isSubmitting}>
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditPhaseModal, useModal: useEditPhaseModal } = createNiceModal<Props>(
  EditPhaseModal,
  'edit-phase-modal',
)
