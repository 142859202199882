import { WppTypography, WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/linear1/phase/Phase.module.scss'
import { FormPhaseWithIndex } from 'types/process/process'
import { routesManager } from 'utils/routesManager'

interface Props {
  pageWidth: number | undefined
  numberOfAllPhases: number
  phase: FormPhaseWithIndex
  index: number
  isActive: boolean
  isAnyActive: boolean
  onPhaseClick: (index: number) => void
}

export const Phase = ({ pageWidth, numberOfAllPhases, phase, isActive, isAnyActive, onPhaseClick }: Props) => {
  const url = (phase.innerPageEnabled && phase?.id ? routesManager.phase.getUrl(phase?.id) : phase?.url) || ''
  const ACTIVE_PHASE_WIDTH = 490
  const PHASE_GAP = 4

  const [isLinkHovered, setIsLinkHovered] = useState(false)

  const phaseWidths = useMemo(() => {
    if (!pageWidth) {
      return
    }

    return {
      phase: pageWidth / numberOfAllPhases - PHASE_GAP,
      activePhase: ACTIVE_PHASE_WIDTH - PHASE_GAP,
      inactivePhase: (pageWidth - ACTIVE_PHASE_WIDTH) / (numberOfAllPhases - 1) - PHASE_GAP,
    }
  }, [pageWidth, numberOfAllPhases])

  const handleLinkHover = useCallback(() => {
    setIsLinkHovered(true)
  }, [])

  const handleLinkLeave = useCallback(() => {
    setIsLinkHovered(false)
  }, [])

  return (
    <div
      className={clsx(styles.phase, isActive && styles.activePhase)}
      style={{
        width: phaseWidths?.[isAnyActive ? (isActive ? 'activePhase' : 'inactivePhase') : 'phase'],
        backgroundColor: !isActive ? phase?.colors?.[0] : phase?.colors?.[1],
      }}
      onClick={() => onPhaseClick(phase.phaseIndex)}
    >
      <WppTypography
        className={styles.phaseIndex}
        style={isActive ? { color: phase?.colors?.[3] } : { color: phase?.colors?.[2] }}
      >
        {phase.phaseIndex}
      </WppTypography>
      <Flex direction="column" justify="end" className={styles.fullheight}>
        {isActive ? (
          <div>
            {url ? (
              <Link to={url} className={styles.link} onMouseEnter={handleLinkHover} onMouseLeave={handleLinkLeave}>
                <WppTypography
                  type="4xl-display"
                  className={clsx(styles.activePhaseTitle, styles.activePhaseTitleLink)}
                  style={{ color: isLinkHovered ? phase?.colors?.[5] : phase?.colors?.[4] }}
                >
                  {phase.title}
                </WppTypography>
              </Link>
            ) : (
              <WppTypography
                type="4xl-display"
                className={styles.activePhaseTitle}
                style={{ color: phase?.colors?.[4] }}
              >
                {phase.title}
              </WppTypography>
            )}
            <div>
              <WppTypography type="s-body" style={{ color: phase?.colors?.[6] }}>
                {phase.description}
              </WppTypography>
            </div>
          </div>
        ) : (
          <Flex direction="column" justify="end" className={styles.fullheight}>
            <Flex justify="between" align="end" gap={4} className={styles.fullheight}>
              <WppTypography type="2xl-heading" className={styles.phaseTitle} style={{ color: phase?.colors?.[4] }}>
                {phase.title}
              </WppTypography>
              <Flex
                justify="center"
                align="center"
                className={styles.iconButton}
                style={{ backgroundColor: phase?.colors?.[7] }}
              >
                <WppIconArrow direction="right" color={phase?.colors?.[8] ?? 'var(--wpp-grey-color-000)'} />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </div>
  )
}
