import { WppIconArrow, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import lightOnImgSrc from 'pages/customWidget/ikea/assets/lamp-light.png'
import lampimgSrc from 'pages/customWidget/ikea/assets/lamp.png'
import styles from 'pages/customWidget/ikea/IkeaProcessWidget.module.scss'
import { IkeaProcessWidgetData } from 'pages/customWidget/ikea/IkeaProcessWidgetData'

const IkeaProcessWidget = () => {
  let indexNumber = 0
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [intercomWidgetNotFound, setIntercomWidgetNotFound] = useState(false)

  useEffect(() => {
    const intercomIcon = document.querySelector('.intercom-launcher')
    if (intercomIcon == null) setIntercomWidgetNotFound(true)
    if (intercomIcon && intercomIcon.parentElement) {
      intercomIcon.parentElement.removeChild(intercomIcon)
    }
  }, [])

  useEffect(() => {
    if (intercomWidgetNotFound) {
      const intercomIcon = document.querySelector('.intercom-launcher')
      if (intercomIcon && intercomIcon.parentElement) {
        intercomIcon.parentElement.removeChild(intercomIcon)
      }
    }
  }, [intercomWidgetNotFound])

  const handlePhaseEnter = () => {
    setIsHovered(true)
  }

  const handlePhaseLeave = () => {
    setIsHovered(false)
  }

  const ikeaProcessWidgetHandler = () => {
    window.parent.location.href = 'https://oppen-ikea.os.wpp.com/process/custom'
  }

  return (
    <Flex
      onMouseEnter={handlePhaseEnter}
      onMouseLeave={handlePhaseLeave}
      className={styles.container}
      direction="column"
      onClick={ikeaProcessWidgetHandler}
    >
      <WppTypography type="m-strong" className={styles.title} tag="span">
        {IkeaProcessWidgetData.title}
      </WppTypography>
      <Flex gap={40}>
        {IkeaProcessWidgetData.phases.map((phase, index: number) => {
          return (
            <Flex key={index} className={styles['phase-container']} direction="column">
              <div className={styles['image-container']}>
                <img
                  src={phase.img}
                  className={clsx(styles['phase-image'], { [styles.active]: isHovered })}
                  alt={phase.phaseId}
                />
              </div>
              <div
                className={clsx(styles['text-container'], { [styles.active]: isHovered })}
                style={{ backgroundColor: phase.bgColor, color: phase.color }}
              >
                <div className={clsx(styles['title-description'], { [styles.show]: isHovered })}>
                  <div className={styles['phase-title']} style={{ color: phase.color }}>
                    {phase.title}
                  </div>
                  <div className={styles['phase-description']} style={{ color: phase.color }}>
                    {phase.description}
                  </div>
                </div>
                <div className={clsx(styles['more-info'], { [styles.show]: isHovered })}>
                  {phase.moreInfo.considerations.map((info: string, ind: number) => {
                    indexNumber++
                    return (
                      <div key={ind} className={styles.info}>
                        <p>{`${indexNumber}. ${info}`}</p>
                      </div>
                    )
                  })}
                </div>
                <Flex className={styles['icon-button-container']} justify="between">
                  <WppIconArrow
                    color={phase.color}
                    direction={isHovered ? 'top' : 'right'}
                    className={styles.icon}
                    width={16}
                  />
                  <Flex className={clsx(styles['button-container'], { [styles.show]: isHovered })}>Learn more</Flex>
                </Flex>
              </div>
            </Flex>
          )
        })}
      </Flex>
      <div>
        <img className={styles['lamp']} src={lampimgSrc} alt="lamp" />
        <img
          className={clsx(isHovered ? styles['light-on'] : styles['light-off'], styles['lamp-light'])}
          src={lightOnImgSrc}
          alt="lamp light"
        />
      </div>
    </Flex>
  )
}

export default IkeaProcessWidget
