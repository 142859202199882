import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse } from '@wpp-open/core'
import * as zod from 'zod'

import { useUploadProcessFilesApi } from 'hooks/attachments/useUploadProcessFilesApi'
import { PhasePage, Templates, PhaseFormConfig } from 'types/phase/phase'
import { isFile } from 'utils/files'

export const getTemplateColors = () => {}

export const getInitialValues = (data: MayBeNull<PhasePage>): PhaseFormConfig => {
  const getFieldValue = ({ name, size, url }: AttachmentMetadataResponse) => ({
    name,
    size,
    url: url || '',
    type: '',
  })

  return {
    template: data?.template || Templates.InnerFirst,
    background: (data?.background ? [getFieldValue(data?.background)] : []) as [FileItemType?],
    colors: data?.colors || [],
    header: {
      title: data?.header?.title || '',
      description: data?.header?.description || '',
      groupTitle: data?.header?.groupTitle || '',
      logo: (data?.header?.logo ? [getFieldValue(data?.header?.logo)] : []) as [FileItemType?],
      background: (data?.header?.background ? [getFieldValue(data?.header?.background)] : []) as [FileItemType?],
      colors: data?.header?.colors || [],
      isFullWidth: Boolean(data?.header?.isFullWidth),
      height: data?.header?.height || 184,
    },
    card: {
      background: (data?.card?.background ? [getFieldValue(data?.card?.background)] : []) as [FileItemType?],
      contentImage: (data?.card?.contentImage ? [getFieldValue(data?.card?.contentImage)] : []) as [FileItemType?],
      colors: data?.card?.colors || [],
      columns:
        data?.card?.columns
          .map(({ icon, ...column }) => ({
            icon: (icon ? [getFieldValue(icon)] : []) as [FileItemType?],
            ...column,
          }))
          .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || [],
      isEnabled: Boolean(data?.card?.isEnabled),
    },
    controls: {
      colors: data?.controls?.colors || [],
      nextUrl: data?.controls?.nextUrl,
      previousUrl: data?.controls?.previousUrl,
      button: {
        title: data?.controls?.button?.title,
        url: data?.controls?.button?.url,
        isVisible: data?.controls?.button?.isVisible,
      },
    },
  }
}

export const useMapFormValuesToPayload = () => {
  const handleUploadProcessFiles = useUploadProcessFilesApi()

  return async ({
    background,
    header: { logo: headerLogo, background: headerBackground, ...header },
    card: { background: cardBackground, contentImage: cardContent, ...card },
    ...values
  }: PhaseFormConfig) => {
    const imagesMetadata = Object.fromEntries(
      await Promise.all(
        Object.entries({ background, headerLogo, headerBackground, cardBackground, cardContent })
          .filter(([_, [value]]) => !value || isFile(value))
          .map(async ([key, [value]]) => [key, value ? (await handleUploadProcessFiles([value as File]))[0] : null]),
      ),
    )

    return {
      ...values,
      background: imagesMetadata.background,
      header: {
        ...header,
        logo: imagesMetadata.headerLogo,
        background: imagesMetadata.headerBackground,
      },
      card: {
        ...card,
        contentImage: imagesMetadata.cardContent,
        background: imagesMetadata.cardBackground,
        columns: await Promise.all(
          card.columns.map(async ({ icon, ...column }, index) => {
            const iconMetadata = isFile(icon?.[0]) ? (await handleUploadProcessFiles([icon?.[0]]))[0] : null

            return {
              ...column,
              ...(!icon?.[0] || isFile(icon?.[0]) ? { icon: iconMetadata } : {}),
              order: index + 1,
            }
          }),
        ),
      },
    }
  }
}

export const getPreviewImageUrl = (value: [FileItemType?]) =>
  isFile(value?.[0]) ? URL.createObjectURL(value?.[0]) : value?.[0]?.url

export const phaseSchema = zod.object({
  template: zod.string().min(1),
  background: zod.array(zod.any()).max(1),
  colors: zod.array(zod.string()),
  header: zod.object({
    title: zod.string().optional().nullable(),
    description: zod.string().optional().nullable(),
    logo: zod.array(zod.any()).max(1),
    background: zod.array(zod.any()).max(1),
    colors: zod.array(zod.string()),
    isFullWidth: zod.boolean(),
    height: zod.number(),
  }),
  card: zod.object({
    background: zod.array(zod.any()).max(1),
    contentImage: zod.array(zod.any()).max(1),
    colors: zod.array(zod.string()),
    columns: zod.array(
      zod.object({
        icon: zod.array(zod.any()).max(1),
        content: zod.string().optional().nullable(),
        id: zod.string().optional().nullable(),
      }),
    ),
    isEnabled: zod.boolean(),
  }),
  controls: zod.object({
    previousUrl: zod.string().optional().nullable(),
    nextUrl: zod.string().optional().nullable(),
    button: zod.object({
      title: zod.string().optional().nullable(),
      url: zod.string().optional().nullable(),
      isVisible: zod.boolean().optional().nullable(),
    }),
    colors: zod.array(zod.string()),
  }),
})

export const paletteTitles: Record<
  Templates,
  { page: string[]; header: string[]; card: string[]; controls: string[] }
> = {
  [Templates.InnerFirst]: {
    page: ['Page background'],
    header: ['Header text', 'Header background'],
    card: ['Card background', 'Column circle background', 'Column text'],
    controls: ['Navigation text', 'Button background', 'Button text'],
  },
  [Templates.InnerSecond]: {
    page: ['Page background'],
    header: ['Header text', 'Header background'],
    card: ['Card background', 'Column circle background', 'Column text'],
    controls: ['Navigation text', 'Button background', 'Button text'],
  },
}
