import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    logo: true,
    widgetBackgroundImage: true,
    widgetPartBackgroundImage: true,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      colors: true,
      innerPageEnabled: true,
      groupTitle: false,
      url: true,
    },
    groups: {
      title: true,
      description: true,
      phasesLocalIds: true,
      colors: true,
    },
  },
  colors: {
    page: {
      titles: [
        'Page background',
        'Widget background',
        'Widget border',
        'Widget inner background',
        'Widget inner border',
        'Widget logo background',
        'Widget logo border',
      ],
      defaultValues: [],
    },
    button: {
      titles: ['Background', 'Text'],
      defaultValues: [],
    },
    phase: {
      titles: ['Point', 'Text', 'Popup text'],
      defaultValues: [],
    },
    group: {
      titles: ['Title', 'Point', 'Overlay', 'Border', 'Popup text', 'Title hover text'],
      defaultValues: [],
    },
  },
}
