// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AGpZV{height:calc(100vh - var(--wpp-os-header-height));padding:32px 38px;background-repeat:no-repeat;background-position:center;background-size:cover}.xdKe8{max-width:var(--wpp-os-content-max-width);margin:0 auto}.fawwK{margin-bottom:48px}.LCYYv{max-width:500px}._SbgR{display:block;margin-bottom:16px}.REdur{align-self:baseline}.knXEl{color:var(--wpp-grey-color-900);background:var(--wpp-white-color);border-radius:var(--wpp-border-radius-m)}.knXEl::part(button){color:inherit;background:inherit;border-radius:var(--wpp-border-radius-m)}.knXEl::part(text){color:inherit}", "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/linear1/LinearProcess1.module.scss"],"names":[],"mappings":"AAAA,OACE,gDAAA,CACA,iBAAA,CACA,2BAAA,CACA,0BAAA,CACA,qBAAA,CAGF,OACE,yCAAA,CACA,aAAA,CAGF,OACE,kBAAA,CAGF,OACE,eAAA,CAGF,OACE,aAAA,CACA,kBAAA,CAGF,OACE,mBAAA,CAGF,OACE,+BAAA,CACA,iCAAA,CACA,wCAAA,CAEA,qBACE,aAAA,CACA,kBAAA,CACA,wCAAA,CAGF,mBACE,aAAA","sourcesContent":[".root {\n  height: calc(100vh - var(--wpp-os-header-height));\n  padding: 32px 38px;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.page {\n  max-width: var(--wpp-os-content-max-width);\n  margin: 0 auto;\n}\n\n.header {\n  margin-bottom: 48px;\n}\n\n.headerTextWrapper {\n  max-width: 500px;\n}\n\n.title {\n  display: block;\n  margin-bottom: 16px;\n}\n\n.projectButtonLink {\n  align-self: baseline;\n}\n\n.projectButton {\n  color: var(--wpp-grey-color-900);\n  background: var(--wpp-white-color);\n  border-radius: var(--wpp-border-radius-m);\n\n  &::part(button) {\n    color: inherit;\n    background: inherit;\n    border-radius: var(--wpp-border-radius-m);\n  }\n\n  &::part(text) {\n    color: inherit;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AGpZV",
	"page": "xdKe8",
	"header": "fawwK",
	"headerTextWrapper": "LCYYv",
	"title": "_SbgR",
	"projectButtonLink": "REdur",
	"projectButton": "knXEl"
};
export default ___CSS_LOADER_EXPORT___;
