import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from 'pages/processBuilder/templates/circular3/phase/Phase.module.scss'
import { getPieItemPath } from 'pages/processBuilder/utils'
import { FormPhase as PhaseConfig } from 'types/process/process'
import { routesManager } from 'utils/routesManager'

export const Phase = ({
  onMouseEnter,
  onMouseLeave,
  isActive,
  activePhase,
  rotation,
  phase,
  size,
}: {
  onMouseEnter: MouseEventHandler
  onMouseLeave: MouseEventHandler
  isActive: boolean
  activePhase: MayBeNull<number>
  rotation: number
  phase: PhaseConfig
  size: number
}) => {
  const navigate = useNavigate()
  const url = (phase.innerPageEnabled && phase?.id ? routesManager.phase.getUrl(phase?.id) : phase?.url) || ''
  const isCustomUrl = phase?.url && !phase.innerPageEnabled
  const overlayLineHeight = 49

  const handleClick = useCallback(() => {
    isCustomUrl ? (window.location.href = url) : navigate(url)
  }, [navigate, isCustomUrl, url])

  const isOverlayInverted = useMemo(() => rotation >= 90 && rotation < 270, [rotation])

  return (
    <g
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      transform={`rotate(${rotation} 641 385) translate(0, -8)`}
      className={clsx({ [styles.phaseInteractive]: url })}
      onClick={handleClick}
    >
      <g opacity={isActive ? 1 : 0}>
        {phase?.overlayDescriptions.length ? (
          phase?.overlayDescriptions?.map((overlay, index) => (
            <g key={index}>
              <path
                d={getPieItemPath({ radius: 300 - index * overlayLineHeight, size, x: 640, y: 395 })}
                mask={`url(#pieItemMask${phase.id}`}
                id={`pieItem${index + 1}`}
              />
              <use
                href={`#pieItem${index + 1}`}
                fill={phase?.colors?.[0] || '#EE3023'}
                mask={`url(#pieItemMask${phase.id}`}
                style={{ filter: `saturate(${1 - index * 0.1}) brightness(${1 - index * 0.1})` }}
              />

              <text
                alignmentBaseline="middle"
                transform={`translate(0, ${index === 3 ? '7' : '-7'})`}
                className={styles.overlayDescription}
              >
                <textPath
                  xlinkHref={`#curve${index + 1}${isOverlayInverted ? '_inverted' : ''}`}
                  fill={phase?.colors?.[3] || '#fff'}
                  startOffset="50%"
                  textAnchor="middle"
                  alignmentBaseline="central"
                >
                  {overlay.label}
                </textPath>
              </text>
            </g>
          ))
        ) : (
          <>
            <path
              d={getPieItemPath({ radius: 300, size, x: 640, y: 395 })}
              mask={`url(#pieItemMask${phase.id}`}
              id="pieItem1"
            />
            <use href="#pieItem1" fill={phase?.colors?.[0] || '#EE3023'} mask={`url(#pieItemMask${phase.id}`} />
          </>
        )}
      </g>

      <text alignmentBaseline="middle" className={styles.phaseTitle}>
        <textPath
          xlinkHref={isOverlayInverted ? '#titlePath_inverted' : '#titlePath'}
          fill={phase?.colors?.[2] || '#000'}
          fillOpacity={!Number.isInteger(activePhase) || isActive ? 1 : 0.3}
          startOffset="50%"
          textAnchor="middle"
          alignmentBaseline="central"
        >
          {phase?.title}
        </textPath>
      </text>

      <circle
        cx="642"
        cy="80"
        r={10}
        fill={phase?.colors?.[1] || 'white'}
        fillOpacity={!Number.isInteger(activePhase) || isActive ? 1 : 0.5}
      />

      <defs>
        <mask id={`pieItemMask${phase.id}`}>
          <use href="#pieItem1" fill="white" />
          <use href="#pieItem2" fill="white" />
          <use href="#pieItem3" fill="white" />
          <use href="#pieItem4" fill="white" />

          <circle
            cx="642"
            cy="395"
            r={phase?.overlayDescriptions.length ? 300 - phase?.overlayDescriptions.length * overlayLineHeight : 104}
            fill="black"
          />
        </mask>

        <path
          d="M 135.357 482.302 C 135.357 386.149 174.47 298.779 237.469 235.781 C 300.467 172.782 387.837 133.669 483.99 133.669 C 580.143 133.669 667.513 172.782 730.511 235.781 C 793.51 298.779 832.623 386.149 832.623 482.302"
          stroke="transparent"
          id="titlePath"
          transform="translate(160 -100) rotate(0)"
        />

        <path
          d="M 832.623 482.302 C 832.623 386.149 793.51 298.779 730.511 235.781 C 667.513 172.782 580.143 133.669 483.99 133.669 C 387.837 133.669 300.467 172.782 237.469 235.781 C 174.47 298.779 135.357 386.149 135.357 482.302"
          stroke="transparent"
          id="titlePath_inverted"
          transform="translate(160 -100) rotate(0)"
        />

        <path
          d="M 603.203 857.528 C 603.203 819.786 587.851 785.491 563.122 760.762 C 538.394 736.034 504.1 720.682 466.357 720.682 C 428.615 720.682 394.32 736.034 369.592 760.762 C 344.864 785.491 329.511 819.786 329.511 857.528"
          id="curve4_inverted"
          transform="rotate(0) translate(173 -458)"
        />
        <path
          d="M 646.549 853.136 C 646.549 804.062 626.587 759.471 594.434 727.318 C 562.282 695.166 517.691 675.204 468.617 675.204 C 419.543 675.204 374.952 695.166 342.8 727.318 C 310.647 759.471 290.685 804.062 290.685 853.136"
          id="curve3_inverted"
          transform="rotate(0) translate(173 -445)"
        />
        <path
          d="M 687.057 843.387 C 687.057 782.553 662.312 727.275 622.454 687.417 C 582.595 647.559 527.318 622.812 466.484 622.812 C 405.648 622.812 350.372 647.559 310.514 687.417 C 270.656 727.275 245.909 782.553 245.909 843.387"
          id="curve2_inverted"
          transform="rotate(0) translate(173 -445)"
        />
        <path
          d="M 741.116 848.504 C 741.116 772.85 710.34 704.108 660.774 654.541 C 611.207 604.973 542.464 574.198 466.811 574.198 C 391.157 574.198 322.414 604.973 272.847 654.541 C 223.279 704.108 192.505 772.85 192.505 848.504"
          id="curve1_inverted"
          transform="rotate(0) translate(173 -445)"
        />

        <path
          d="M 329.511 857.528 C 329.511 819.786 344.864 785.491 369.592 760.762 C 394.32 736.034 428.615 720.682 466.357 720.682 C 504.1 720.682 538.394 736.034 563.122 760.762 C 587.851 785.491 603.203 819.786 603.203 857.528"
          id="curve4"
          transform="rotate(0) translate(173 -458)"
        />
        <path
          d="M 290.685 853.136 C 290.685 804.062 310.647 759.471 342.8 727.318 C 374.952 695.166 419.543 675.204 468.617 675.204 C 517.691 675.204 562.282 695.166 594.434 727.318 C 626.587 759.471 646.549 804.062 646.549 853.136"
          id="curve3"
          transform="rotate(0) translate(173 -445)"
        />
        <path
          d="M 245.909 843.387 C 245.909 782.553 270.656 727.275 310.514 687.417 C 350.372 647.559 405.648 622.812 466.484 622.812 C 527.318 622.812 582.595 647.559 622.454 687.417 C 662.312 727.275 687.057 782.553 687.057 843.387"
          id="curve2"
          transform="rotate(0) translate(173 -445)"
        />
        <path
          d="M 192.505 848.504 C 192.505 772.85 223.279 704.108 272.847 654.541 C 322.414 604.973 391.157 574.198 466.811 574.198 C 542.464 574.198 611.207 604.973 660.774 654.541 C 710.34 704.108 741.116 772.85 741.116 848.504"
          id="curve1"
          transform="rotate(0) translate(173 -445)"
        />
      </defs>
    </g>
  )
}
