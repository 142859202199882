import {
  WppButton,
  WppTypography,
  WppActionButton,
  WppIconPlus,
  WppCard,
  WppIconTrash,
  WppAccordion,
  WppDivider,
  WppInlineMessage,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { useMemo, useEffect, useCallback } from 'react'
import { FormProvider, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateProcessApi } from 'api/process/mutations/useCreateProcessApi'
import { useEditProcessApi } from 'api/process/mutations/useEditProcessApi'
import { useProcessApi } from 'api/process/queries/useFetchProcessApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/processBuilder/editProcessModal/EditProcessModal.module.scss'
import { templateConfigs } from 'pages/processBuilder/templates'
import {
  useMapFormValuesToPayload,
  getInitialValues,
  processSchema,
  getIsFieldHidden,
} from 'pages/processBuilder/utils'
import { Templates, FormPhase } from 'types/process/process'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onChange: Function
  resetEditState: () => void
}

export const EditProcessModal = ({ isOpen, onClose, onCloseComplete, onChange, resetEditState }: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const {
    osContext: { tenant },
  } = useOs()
  const queryClient = useQueryClient()
  const mapFormValuesToPayload = useMapFormValuesToPayload()

  const { data } = useProcessApi({
    params: {
      tenantId: tenant.id,
    },
  })
  const { mutateAsync: handleCreateProcess } = useCreateProcessApi()
  const { mutateAsync: handleEditProcess } = useEditProcessApi()

  const form = useForm({
    defaultValues: getInitialValues(data),
    validationSchema: processSchema,
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    watch,
    control,
    register,
    setValue,
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      data
        ? await handleEditProcess({
            process: await mapFormValuesToPayload(values, true),
            processId: data.id,
          })
        : await handleCreateProcess({
            process: await mapFormValuesToPayload(values, false),
          })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.PROCESS] })

      enqueueToast({
        message: t('process.edit_modal.configured_successfully'),
        type: 'success',
      })
      onClose()
    } catch (e) {
      enqueueToast({
        message: t('process.edit_modal.not_configured'),
        type: 'error',
      })
    }
  })

  const handleCloseComplete = useCallback(() => {
    onCloseComplete()
    resetEditState()
  }, [onCloseComplete, resetEditState])

  const templateOptions = useMemo(() => Object.values(Templates).map(value => ({ value, label: value })), [])

  const formData = watch()

  const isFieldHidden = getIsFieldHidden(formData.template as Templates)
  const colorsConfig = templateConfigs[formData.template as Templates].colors

  const {
    fields: phases,
    append: appendPhase,
    remove: removePhase,
  } = useFieldArray({
    control,
    name: 'phases',
    keyName: 'formId',
  })

  const {
    fields: groups,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: 'groups',
    keyName: 'formId',
  })

  const groupPhasesOptions = useMemo(
    () => formData?.phases?.map(({ localId }, index) => ({ value: localId, label: `Phase ${index + 1}` })) || [],
    [formData],
  )

  useEffect(() => {
    onChange(formData)
  }, [formData, onChange])

  const handleAddPhase = useCallback(() => {
    appendPhase({
      title: 'Phase title',
      description: 'Description',
      overlayDescriptions: [{ label: 'Overlay 1', id: 0 }],
      colors: [],
      groupTitle: '',
      url: '',
      innerPageEnabled: false,
    })
  }, [appendPhase])

  const handleRemovePhase = useCallback(
    (index: number, formId: string) => () => {
      removePhase(index)

      formData.groups?.forEach(({ phasesLocalIds }, index) => {
        const updatedIds = phasesLocalIds?.filter(id => id !== formId) || []
        setValue(`groups.${index}.phasesLocalIds`, updatedIds)
      })
    },
    [removePhase, formData.groups, setValue],
  )

  const addNewOverlayTitle = useCallback(
    (index: number) => (event: any) => {
      const phase = formData?.phases?.[index] as FormPhase

      setValue(
        `phases.${index}.overlayDescriptions`,
        [...phase.overlayDescriptions, { label: event.detail, id: phase.overlayDescriptions.length }],
        { shouldDirty: true },
      )
      event.target?.hideDropdown()
      event.target?.blurInput()
    },
    [formData?.phases, setValue],
  )

  const handleAddGroup = useCallback(() => {
    appendGroup({
      phasesLocalIds: [],
      title: 'Group title',
      description: '',
      colors: [],
    })
  }, [appendGroup])

  const handleRemoveGroup = useCallback(
    (index: number) => () => {
      removeGroup(index)
    },
    [removeGroup],
  )

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{ onSubmit }}
        size="m"
        disableOutsideClick
        open={isOpen}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={handleCloseComplete}
        className={styles.modal}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('process.edit_modal.edit_process')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={28} className={styles.modalBody}>
          <FormSelect
            labelConfig={{ text: t('process.edit_modal.template') }}
            required
            withSearch
            placeholder={t('process.edit_modal.choose_template')}
            name="template"
            options={templateOptions}
            defaultValue={Templates.Circular1}
            hidden={isFieldHidden('template')}
          />

          {!isFieldHidden('title') || !isFieldHidden('description') ? <WppDivider /> : null}
          <FormTextInput
            name="title"
            labelConfig={{ text: t('process.edit_modal.title') }}
            placeholder={t('process.edit_modal.title')}
            hidden={isFieldHidden('title')}
          />
          <FormTextInput
            name="description"
            labelConfig={{ text: t('process.edit_modal.description') }}
            placeholder={t('process.edit_modal.description')}
            hidden={isFieldHidden('description')}
          />
          {!isFieldHidden('title') || !isFieldHidden('description') ? <WppDivider /> : null}

          <FormFileUpload
            name="background"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_background')}
            hidden={isFieldHidden('background')}
          />

          <FormFileUpload
            name="logo"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_logo')}
            hidden={isFieldHidden('logo')}
          />

          <FormFileUpload
            name="widgetBackgroundImage"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_widget_background')}
            hidden={isFieldHidden('widgetBackgroundImage')}
          />

          <Flex direction="column" gap={5} align="center">
            <FormFileUpload
              name="widgetPartBackgroundImage"
              size={1}
              acceptConfig={{
                'image/svg+xml': ['.svg'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
              }}
              maxLabelLength={45}
              maxFiles={1}
              className={styles.fileInput}
              label={t('process.edit_modal.choose_widget_hover_background')}
              hidden={isFieldHidden('widgetPartBackgroundImage')}
            />
            <WppInlineMessage
              hidden={isFieldHidden('widgetPartBackgroundImage')}
              type="information"
              message={t('process.edit_modal.widget_hover_background_info')}
            />
          </Flex>

          <Flex direction="column" gap={5} align="center">
            <FormPalette name="colors" titles={colorsConfig.page.titles} className={styles.colorsInput} />

            <WppInlineMessage
              hidden={formData.template !== Templates.Circular3}
              type="information"
              message={t('process.edit_modal.circular3_border_color_info')}
            />
          </Flex>

          {!isFieldHidden('button') && (
            <WppCard>
              <Flex direction="column" gap={10}>
                <WppTypography type="s-strong" className={styles.cardTitle}>
                  {t('process.edit_modal.button')}
                </WppTypography>
                <Flex direction="row" justify="between" align="end" gap={15}>
                  <FormTextInput
                    name="button.title"
                    placeholder={t('process.edit_modal.title')}
                    className={styles.inputHalfRow}
                    hidden={isFieldHidden('button', 'title')}
                  />
                  <FormTextInput
                    name="button.url"
                    placeholder={t('process.edit_modal.link')}
                    className={styles.inputHalfRow}
                    hidden={isFieldHidden('button', 'url')}
                  />
                </Flex>
                <FormPalette
                  name="button.colors"
                  titles={colorsConfig.button.titles}
                  hidden={isFieldHidden('button', 'colors')}
                />
                <FormCheckbox
                  name="button.isVisible"
                  labelConfig={{
                    text: t('process.edit_modal.visibility'),
                  }}
                  hidden={isFieldHidden('button', 'isVisible')}
                />
              </Flex>
            </WppCard>
          )}

          <WppDivider />

          <Flex direction="column" gap={20}>
            {!isFieldHidden('phases') && (
              <>
                <WppTypography type="m-strong">{t('process.edit_modal.phases')}</WppTypography>

                {phases.map((item, index) => (
                  <WppCard key={item.formId}>
                    {/* TODO: create an empty phase via api on add click and provide list of ids for group */}
                    <input
                      type="text"
                      value={item.id || item.formId}
                      {...register(`phases.${index}.localId`, { value: item.id || item.formId })}
                      hidden
                    />

                    <WppAccordion withDivider={false} className={styles.phaseAccordion}>
                      <WppTypography type="s-strong" slot="header">
                        {t('process.edit_modal.phase_number', { number: index + 1 })}
                      </WppTypography>
                      <WppActionButton
                        className={styles.removePhaseBtn}
                        variant="primary"
                        onClick={handleRemovePhase(index, item.formId)}
                        slot="actions"
                      >
                        <WppIconTrash slot="icon-start" />
                      </WppActionButton>

                      <Flex direction="column" gap={10} className={styles.phaseFieldsWrap}>
                        <Controller
                          render={({ field }) => (
                            <FormTextareaInput
                              {...field}
                              placeholder={t('process.edit_modal.title')}
                              hidden={isFieldHidden('phases', 'title')}
                              rows={1}
                            />
                          )}
                          name={`phases.${index}.title`}
                          control={control}
                        />

                        <Controller
                          render={({ field }) => (
                            <FormTextInput
                              {...field}
                              placeholder={t('process.edit_modal.description')}
                              hidden={isFieldHidden('phases', 'description')}
                            />
                          )}
                          name={`phases.${index}.description`}
                          control={control}
                        />

                        <Controller
                          render={({ field }) => (
                            <FormAutocomplete
                              {...field}
                              showCreateNewElement
                              options={[]}
                              multiple
                              onWppCreateNewOption={addNewOverlayTitle(index)}
                              simpleSearch
                              defaultValue={[]}
                              placeholder={t('process.edit_modal.overlays')}
                              className={styles.phaseAutocomplete}
                              hidden={isFieldHidden('phases', 'overlayDescriptions')}
                            />
                          )}
                          name={`phases.${index}.overlayDescriptions`}
                          control={control}
                        />

                        <FormPalette
                          {...register(`phases.${index}.colors`)}
                          titles={colorsConfig.phase.titles}
                          hidden={isFieldHidden('phases', 'colors')}
                        />

                        <Flex direction="row" justify="between" align="center" gap={15} wrap="wrap">
                          <Controller
                            render={({ field }) => (
                              <FormTextInput
                                {...field}
                                placeholder={t('process.edit_modal.custom_link')}
                                className={styles.inputHalfRow}
                                disabled={formData.phases?.[index].innerPageEnabled}
                                hidden={isFieldHidden('phases', 'url')}
                              />
                            )}
                            name={`phases.${index}.url`}
                            control={control}
                          />

                          <Controller
                            render={({ field }) => (
                              <FormTextInput
                                {...field}
                                placeholder={t('process.edit_modal.group_title')}
                                className={styles.inputHalfRow}
                                hidden={isFieldHidden('phases', 'groupTitle')}
                              />
                            )}
                            name={`phases.${index}.groupTitle`}
                            control={control}
                          />

                          <FormCheckbox
                            {...register(`phases.${index}.innerPageEnabled`)}
                            labelConfig={{
                              text: t('process.edit_modal.enable_inner_page'),
                            }}
                            className={styles.inputHalfRow}
                            hidden={isFieldHidden('phases', 'innerPageEnabled')}
                          />
                        </Flex>
                      </Flex>
                    </WppAccordion>
                  </WppCard>
                ))}

                <WppActionButton onClick={handleAddPhase}>
                  <WppIconPlus slot="icon-start" />
                  {t('process.edit_modal.add_phase')}
                </WppActionButton>
              </>
            )}

            {!isFieldHidden('groups') && (
              <>
                <WppDivider />

                <WppTypography type="m-strong">{t('process.edit_modal.groups')}</WppTypography>

                {groups.map((item: any, index: number) => (
                  <WppCard key={item.formId} className={styles.groupCard}>
                    <WppAccordion withDivider={false} className={styles.phaseAccordion}>
                      <WppTypography type="s-strong" slot="header">
                        {t('process.edit_modal.group_number', { number: index + 1 })}
                      </WppTypography>
                      <WppActionButton
                        className={styles.removePhaseBtn}
                        variant="primary"
                        onClick={handleRemoveGroup(index)}
                        slot="actions"
                      >
                        <WppIconTrash slot="icon-start" />
                      </WppActionButton>

                      <Flex direction="column" gap={10} className={styles.phaseFieldsWrap}>
                        <Controller
                          render={({ field }) => (
                            <FormTextInput
                              {...field}
                              name={`groups.${index}.title`}
                              placeholder={t('process.edit_modal.title')}
                              hidden={isFieldHidden('groups', 'title')}
                            />
                          )}
                          name={`groups.${index}.title`}
                          control={control}
                        />

                        <Controller
                          render={({ field }) => (
                            <FormTextInput
                              {...field}
                              name={`groups.${index}.description`}
                              placeholder={t('process.edit_modal.description')}
                              hidden={isFieldHidden('groups', 'description')}
                            />
                          )}
                          name={`groups.${index}.description`}
                          control={control}
                        />

                        <Controller
                          render={({ field }) => (
                            <FormSelect
                              withSearch
                              placeholder="Children phases"
                              {...field}
                              options={groupPhasesOptions}
                              defaultValue={[]}
                              type="multiple"
                              hidden={isFieldHidden('groups', 'phasesLocalIds')}
                            />
                          )}
                          name={`groups.${index}.phasesLocalIds`}
                          control={control}
                        />

                        <FormPalette
                          {...register(`groups.${index}.colors`)}
                          titles={colorsConfig.group.titles}
                          hidden={isFieldHidden('groups', 'colors')}
                        />
                      </Flex>
                    </WppAccordion>
                  </WppCard>
                ))}

                <WppActionButton onClick={handleAddGroup}>
                  <WppIconPlus slot="icon-start" />
                  {t('process.edit_modal.add_group')}
                </WppActionButton>
              </>
            )}
          </Flex>
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose}>
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" disabled={(data && !isDirty) || !isValid} loading={isSubmitting}>
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditProcessModal, useModal: useEditProcessModal } = createNiceModal<Props>(
  EditProcessModal,
  'edit-process-modal',
)
