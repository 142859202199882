export enum Tenants {
  Audible = 'Audible',
  Boots = 'Boots',
  Pfizer = 'Pfizer',
  EasyJet = 'EasyJet',
  Nestle = 'Nestle',
  NestleProd = 'Nestlé',
  NestleChina = 'Nestle China',
  NestleAsia = 'Nestle-Asia',
  NestleAOA = 'Nestle AOA',
  NestleHealthScience = 'Nestle Health Science',
  NestleUK = 'Nestle UK',
  NestleMEAP = 'Nestle MEAP',
  Beiersdorf = 'Beiersdorf',
  AXA = 'AXA',
  Orange = 'Orange',
  Aldi = 'Aldi',
  Perrigo = 'Perrigo',
  Panera = 'Panera',
  Allianz = 'Allianz',
  Spotify = 'Spotify',
  Tapestry = 'Tapestry',
  Unilever = 'Unilever',
  Google = 'Google',
  Mattressfirm = 'Mattressfirm',
  Michelin = 'Michelin',
  IBM = 'IBM',
  Ikea = 'Ikea',
  IKEA = 'IKEA',
  Vodafone = 'Vodafone',
  TCCC = 'TCCC',
  TCCC_China = 'TCCC China',
  No7Beauty = 'no7beauty',
  Ferrero = 'Ferrero',
  Fossil = 'Fossil',
  WarnerBrothers = 'Warner Brothers',
  LVMH = 'LVMH',
  LVMHAPAC = 'LVMH APAC',
  Samsung = 'Samsung',
  Henkel = 'Henkel',
  ProcterAndGamble = 'Procter & Gamble',
  ASML = 'ASML',
  Netflix = 'Netflix',
  Loccitane = 'Loccitane',
  FordMotorChina = 'Ford Motor China',
  TigerBrands = 'Tiger Brands',
  Loreal = 'Loreal',
  LOreal = "L'Oreal",
  MediaMarktSaturn = 'MediaMarktSaturn',
  BritishPetroleum = 'British Petroleum',
  Lego = 'Lego',
  YumChina = 'Yum China',
  Shell = 'Shell',
  TUI = 'TUI',
}
