import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    logo: true,
    widgetBackgroundImage: true,
    widgetPartBackgroundImage: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      colors: true,
      innerPageEnabled: true,
      groupTitle: true,
      url: true,
    },
    groups: false,
  },
  colors: {
    page: {
      titles: ['Page background', 'Widget background', 'Widget logo background', 'Widget border'],
      defaultValues: [],
    },
    button: {
      titles: ['Background', 'Text'],
      defaultValues: [],
    },
    phase: {
      titles: [
        'Overlay background',
        'Point',
        'Circle title',
        'Overlay text',
        'Description text',
        'Gradient color',
        'Logo background',
        'Group title',
      ],
      defaultValues: [],
    },
    group: {
      titles: [],
      defaultValues: [],
    },
  },
}
