// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hq9JV{cursor:pointer}.UVIwZ{font-weight:700;font-size:24px;font-family:var(--wpp-font-family, sans-serif);font-style:normal;transition:.3s ease;will-change:opacity}.AX8bX{font-weight:600;font-size:16px;font-family:var(--wpp-font-family, sans-serif);font-style:normal;transition:.3s ease;will-change:opacity}.a_cec{display:-webkit-box;margin-bottom:8px;font-weight:600;font-size:20px;font-family:var(--wpp-font-family, sans-serif);line-height:32px;text-transform:uppercase}.m4ixM{display:-webkit-box;font-weight:400;font-size:16px;font-family:var(--wpp-font-family, sans-serif);line-height:24px}", "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/circular4/phase/Phase.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAGF,OACE,eAAA,CACA,cAAA,CACA,8CAAA,CACA,iBAAA,CACA,mBAAA,CACA,mBAAA,CAGF,OACE,eAAA,CACA,cAAA,CACA,8CAAA,CACA,iBAAA,CACA,mBAAA,CACA,mBAAA,CAGF,OACE,mBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,8CAAA,CACA,gBAAA,CACA,wBAAA,CAGF,OACE,mBAAA,CACA,eAAA,CACA,cAAA,CACA,8CAAA,CACA,gBAAA","sourcesContent":[".phaseInteractive {\n  cursor: pointer;\n}\n\n.title {\n  font-weight: 700;\n  font-size: 24px;\n  font-family: var(--wpp-font-family, sans-serif);\n  font-style: normal;\n  transition: 0.3s ease;\n  will-change: opacity;\n}\n\n.overlayDescription {\n  font-weight: 600;\n  font-size: 16px;\n  font-family: var(--wpp-font-family, sans-serif);\n  font-style: normal;\n  transition: 0.3s ease;\n  will-change: opacity;\n}\n\n.descriptionTitle {\n  display: -webkit-box;\n  margin-bottom: 8px;\n  font-weight: 600;\n  font-size: 20px;\n  font-family: var(--wpp-font-family, sans-serif);\n  line-height: 32px;\n  text-transform: uppercase;\n}\n\n.descriptionText {\n  display: -webkit-box;\n  font-weight: 400;\n  font-size: 16px;\n  font-family: var(--wpp-font-family, sans-serif);\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phaseInteractive": "Hq9JV",
	"title": "UVIwZ",
	"overlayDescription": "AX8bX",
	"descriptionTitle": "a_cec",
	"descriptionText": "m4ixM"
};
export default ___CSS_LOADER_EXPORT___;
